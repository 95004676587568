import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2db06d4b = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _3d7c5312 = () => interopDefault(import('../pages/categorias.vue' /* webpackChunkName: "pages/categorias" */))
const _7c58c5e3 = () => interopDefault(import('../pages/contato.vue' /* webpackChunkName: "pages/contato" */))
const _3c270d4f = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _7a108c07 = () => interopDefault(import('../pages/jurados.vue' /* webpackChunkName: "pages/jurados" */))
const _782207ba = () => interopDefault(import('../pages/perfil.vue' /* webpackChunkName: "pages/perfil" */))
const _007799df = () => interopDefault(import('../pages/projeto/index.vue' /* webpackChunkName: "pages/projeto/index" */))
const _037e3772 = () => interopDefault(import('../pages/projetos.vue' /* webpackChunkName: "pages/projetos" */))
const _2d2fe7b4 = () => interopDefault(import('../pages/regulamento.vue' /* webpackChunkName: "pages/regulamento" */))
const _6a94eb6b = () => interopDefault(import('../pages/vencedores.vue' /* webpackChunkName: "pages/vencedores" */))
const _4f72b1b9 = () => interopDefault(import('../pages/admin/actions/index.vue' /* webpackChunkName: "pages/admin/actions/index" */))
const _d83cd1ac = () => interopDefault(import('../pages/admin/branches/index.vue' /* webpackChunkName: "pages/admin/branches/index" */))
const _5ee108b6 = () => interopDefault(import('../pages/admin/categories/index.vue' /* webpackChunkName: "pages/admin/categories/index" */))
const _800996f8 = () => interopDefault(import('../pages/admin/category-types/index.vue' /* webpackChunkName: "pages/admin/category-types/index" */))
const _6b15c9e2 = () => interopDefault(import('../pages/admin/commands/index.vue' /* webpackChunkName: "pages/admin/commands/index" */))
const _3a699656 = () => interopDefault(import('../pages/admin/cpf-block/index.vue' /* webpackChunkName: "pages/admin/cpf-block/index" */))
const _ac59b4e6 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _9826c37c = () => interopDefault(import('../pages/admin/dispatches/index.vue' /* webpackChunkName: "pages/admin/dispatches/index" */))
const _93f184e2 = () => interopDefault(import('../pages/admin/editions/index.vue' /* webpackChunkName: "pages/admin/editions/index" */))
const _2ca39778 = () => interopDefault(import('../pages/admin/email-templates/index.vue' /* webpackChunkName: "pages/admin/email-templates/index" */))
const _1eed3d0a = () => interopDefault(import('../pages/admin/exports/index.vue' /* webpackChunkName: "pages/admin/exports/index" */))
const _28510112 = () => interopDefault(import('../pages/admin/faq/index.vue' /* webpackChunkName: "pages/admin/faq/index" */))
const _4b56dd96 = () => interopDefault(import('../pages/admin/grades/index.vue' /* webpackChunkName: "pages/admin/grades/index" */))
const _7e34c9fc = () => interopDefault(import('../pages/admin/indexes/index.vue' /* webpackChunkName: "pages/admin/indexes/index" */))
const _dde7aab0 = () => interopDefault(import('../pages/admin/ips/index.vue' /* webpackChunkName: "pages/admin/ips/index" */))
const _1d188208 = () => interopDefault(import('../pages/admin/market-jury.vue' /* webpackChunkName: "pages/admin/market-jury" */))
const _01140800 = () => interopDefault(import('../pages/admin/permissions/index.vue' /* webpackChunkName: "pages/admin/permissions/index" */))
const _5e04dd58 = () => interopDefault(import('../pages/admin/projects/index.vue' /* webpackChunkName: "pages/admin/projects/index" */))
const _4aefca6a = () => interopDefault(import('../pages/admin/rating-criteria/index.vue' /* webpackChunkName: "pages/admin/rating-criteria/index" */))
const _35da0ad4 = () => interopDefault(import('../pages/admin/registrations/index.vue' /* webpackChunkName: "pages/admin/registrations/index" */))
const _69a5180c = () => interopDefault(import('../pages/admin/statistics.vue' /* webpackChunkName: "pages/admin/statistics" */))
const _644d2d78 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _b9607fe0 = () => interopDefault(import('../pages/admin/winners/index.vue' /* webpackChunkName: "pages/admin/winners/index" */))
const _4b77c0a3 = () => interopDefault(import('../pages/auth/forgot.vue' /* webpackChunkName: "pages/auth/forgot" */))
const _84f9b7ce = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7e56d223 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _1e40f5ba = () => interopDefault(import('../pages/admin/auth/forgot.vue' /* webpackChunkName: "pages/admin/auth/forgot" */))
const _bc5e60ce = () => interopDefault(import('../pages/admin/auth/login.vue' /* webpackChunkName: "pages/admin/auth/login" */))
const _b6174e70 = () => interopDefault(import('../pages/admin/categories/create.vue' /* webpackChunkName: "pages/admin/categories/create" */))
const _671fa6ba = () => interopDefault(import('../pages/admin/category-types/create.vue' /* webpackChunkName: "pages/admin/category-types/create" */))
const _1d491688 = () => interopDefault(import('../pages/admin/dispatches/create.vue' /* webpackChunkName: "pages/admin/dispatches/create" */))
const _3294bf0f = () => interopDefault(import('../pages/admin/editions/create.vue' /* webpackChunkName: "pages/admin/editions/create" */))
const _73cc9efa = () => interopDefault(import('../pages/admin/email-templates/create.vue' /* webpackChunkName: "pages/admin/email-templates/create" */))
const _09836bec = () => interopDefault(import('../pages/admin/faq/create.vue' /* webpackChunkName: "pages/admin/faq/create" */))
const _c3a0ed5a = () => interopDefault(import('../pages/admin/rating-criteria/create.vue' /* webpackChunkName: "pages/admin/rating-criteria/create" */))
const _150809fa = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _7ffb48cb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _cc176b8c = () => interopDefault(import('../pages/admin/auth/reset/_token.vue' /* webpackChunkName: "pages/admin/auth/reset/_token" */))
const _1cd69e81 = () => interopDefault(import('../pages/admin/categories/edit/_id.vue' /* webpackChunkName: "pages/admin/categories/edit/_id" */))
const _016637f3 = () => interopDefault(import('../pages/admin/category-types/edit/_id.vue' /* webpackChunkName: "pages/admin/category-types/edit/_id" */))
const _7812e1f0 = () => interopDefault(import('../pages/admin/editions/edit/_id.vue' /* webpackChunkName: "pages/admin/editions/edit/_id" */))
const _d293bf9a = () => interopDefault(import('../pages/admin/email-templates/edit/_id.vue' /* webpackChunkName: "pages/admin/email-templates/edit/_id" */))
const _cc250eb6 = () => interopDefault(import('../pages/admin/faq/edit/_id.vue' /* webpackChunkName: "pages/admin/faq/edit/_id" */))
const _41061da1 = () => interopDefault(import('../pages/admin/grades/edit/_id.vue' /* webpackChunkName: "pages/admin/grades/edit/_id" */))
const _97f04f68 = () => interopDefault(import('../pages/admin/rating-criteria/edit/_id.vue' /* webpackChunkName: "pages/admin/rating-criteria/edit/_id" */))
const _527a699a = () => interopDefault(import('../pages/admin/users/edit/_id.vue' /* webpackChunkName: "pages/admin/users/edit/_id" */))
const _6143ce61 = () => interopDefault(import('../pages/admin/actions/_id.vue' /* webpackChunkName: "pages/admin/actions/_id" */))
const _e99d6dac = () => interopDefault(import('../pages/admin/dispatches/_id.vue' /* webpackChunkName: "pages/admin/dispatches/_id" */))
const _6c1dd11c = () => interopDefault(import('../pages/admin/ips/_ip.vue' /* webpackChunkName: "pages/admin/ips/_ip" */))
const _28b04e88 = () => interopDefault(import('../pages/admin/projects/_id.vue' /* webpackChunkName: "pages/admin/projects/_id" */))
const _552aaa7e = () => interopDefault(import('../pages/admin/registrations/_id.vue' /* webpackChunkName: "pages/admin/registrations/_id" */))
const _2551e5ba = () => interopDefault(import('../pages/auth/reset/_token.vue' /* webpackChunkName: "pages/auth/reset/_token" */))
const _4d7d10b8 = () => interopDefault(import('../pages/projeto/edit/_id.vue' /* webpackChunkName: "pages/projeto/edit/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _2db06d4b,
    name: "admin"
  }, {
    path: "/categorias",
    component: _3d7c5312,
    name: "categorias"
  }, {
    path: "/contato",
    component: _7c58c5e3,
    name: "contato"
  }, {
    path: "/faq",
    component: _3c270d4f,
    name: "faq"
  }, {
    path: "/jurados",
    component: _7a108c07,
    name: "jurados"
  }, {
    path: "/perfil",
    component: _782207ba,
    name: "perfil"
  }, {
    path: "/projeto",
    component: _007799df,
    name: "projeto"
  }, {
    path: "/projetos",
    component: _037e3772,
    name: "projetos"
  }, {
    path: "/regulamento",
    component: _2d2fe7b4,
    name: "regulamento"
  }, {
    path: "/vencedores",
    component: _6a94eb6b,
    name: "vencedores"
  }, {
    path: "/admin/actions",
    component: _4f72b1b9,
    name: "admin-actions"
  }, {
    path: "/admin/branches",
    component: _d83cd1ac,
    name: "admin-branches"
  }, {
    path: "/admin/categories",
    component: _5ee108b6,
    name: "admin-categories"
  }, {
    path: "/admin/category-types",
    component: _800996f8,
    name: "admin-category-types"
  }, {
    path: "/admin/commands",
    component: _6b15c9e2,
    name: "admin-commands"
  }, {
    path: "/admin/cpf-block",
    component: _3a699656,
    name: "admin-cpf-block"
  }, {
    path: "/admin/dashboard",
    component: _ac59b4e6,
    name: "admin-dashboard"
  }, {
    path: "/admin/dispatches",
    component: _9826c37c,
    name: "admin-dispatches"
  }, {
    path: "/admin/editions",
    component: _93f184e2,
    name: "admin-editions"
  }, {
    path: "/admin/email-templates",
    component: _2ca39778,
    name: "admin-email-templates"
  }, {
    path: "/admin/exports",
    component: _1eed3d0a,
    name: "admin-exports"
  }, {
    path: "/admin/faq",
    component: _28510112,
    name: "admin-faq"
  }, {
    path: "/admin/grades",
    component: _4b56dd96,
    name: "admin-grades"
  }, {
    path: "/admin/indexes",
    component: _7e34c9fc,
    name: "admin-indexes"
  }, {
    path: "/admin/ips",
    component: _dde7aab0,
    name: "admin-ips"
  }, {
    path: "/admin/market-jury",
    component: _1d188208,
    name: "admin-market-jury"
  }, {
    path: "/admin/permissions",
    component: _01140800,
    name: "admin-permissions"
  }, {
    path: "/admin/projects",
    component: _5e04dd58,
    name: "admin-projects"
  }, {
    path: "/admin/rating-criteria",
    component: _4aefca6a,
    name: "admin-rating-criteria"
  }, {
    path: "/admin/registrations",
    component: _35da0ad4,
    name: "admin-registrations"
  }, {
    path: "/admin/statistics",
    component: _69a5180c,
    name: "admin-statistics"
  }, {
    path: "/admin/users",
    component: _644d2d78,
    name: "admin-users"
  }, {
    path: "/admin/winners",
    component: _b9607fe0,
    name: "admin-winners"
  }, {
    path: "/forgot",
    component: _4b77c0a3,
    name: "auth-forgot"
  }, {
    path: "/login",
    component: _84f9b7ce,
    name: "auth-login"
  }, {
    path: "/register",
    component: _7e56d223,
    name: "auth-register"
  }, {
    path: "/admin/forgot",
    component: _1e40f5ba,
    name: "admin-auth-forgot"
  }, {
    path: "/admin/login",
    component: _bc5e60ce,
    name: "admin-auth-login"
  }, {
    path: "/admin/categories/create",
    component: _b6174e70,
    name: "admin-categories-create"
  }, {
    path: "/admin/category-types/create",
    component: _671fa6ba,
    name: "admin-category-types-create"
  }, {
    path: "/admin/dispatches/create",
    component: _1d491688,
    name: "admin-dispatches-create"
  }, {
    path: "/admin/editions/create",
    component: _3294bf0f,
    name: "admin-editions-create"
  }, {
    path: "/admin/email-templates/create",
    component: _73cc9efa,
    name: "admin-email-templates-create"
  }, {
    path: "/admin/faq/create",
    component: _09836bec,
    name: "admin-faq-create"
  }, {
    path: "/admin/rating-criteria/create",
    component: _c3a0ed5a,
    name: "admin-rating-criteria-create"
  }, {
    path: "/admin/users/create",
    component: _150809fa,
    name: "admin-users-create"
  }, {
    path: "/",
    component: _7ffb48cb,
    name: "index"
  }, {
    path: "/admin/reset/:token?",
    component: _cc176b8c,
    name: "admin-auth-reset-token"
  }, {
    path: "/admin/categories/edit/:id?",
    component: _1cd69e81,
    name: "admin-categories-edit-id"
  }, {
    path: "/admin/category-types/edit/:id",
    component: _016637f3,
    name: "admin-category-types-edit-id"
  }, {
    path: "/admin/editions/edit/:id?",
    component: _7812e1f0,
    name: "admin-editions-edit-id"
  }, {
    path: "/admin/email-templates/edit/:id",
    component: _d293bf9a,
    name: "admin-email-templates-edit-id"
  }, {
    path: "/admin/faq/edit/:id?",
    component: _cc250eb6,
    name: "admin-faq-edit-id"
  }, {
    path: "/admin/grades/edit/:id?",
    component: _41061da1,
    name: "admin-grades-edit-id"
  }, {
    path: "/admin/rating-criteria/edit/:id",
    component: _97f04f68,
    name: "admin-rating-criteria-edit-id"
  }, {
    path: "/admin/users/edit/:id?",
    component: _527a699a,
    name: "admin-users-edit-id"
  }, {
    path: "/admin/actions/:id",
    component: _6143ce61,
    name: "admin-actions-id"
  }, {
    path: "/admin/dispatches/:id",
    component: _e99d6dac,
    name: "admin-dispatches-id"
  }, {
    path: "/admin/ips/:ip",
    component: _6c1dd11c,
    name: "admin-ips-ip"
  }, {
    path: "/admin/projects/:id",
    component: _28b04e88,
    name: "admin-projects-id"
  }, {
    path: "/admin/registrations/:id",
    component: _552aaa7e,
    name: "admin-registrations-id"
  }, {
    path: "/reset/:token?",
    component: _2551e5ba,
    name: "auth-reset-token"
  }, {
    path: "/projeto/edit/:id?",
    component: _4d7d10b8,
    name: "projeto-edit-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
